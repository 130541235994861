import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import FormAlert from "./FormAlert";
import EditItemModal from "./EditItemModal";
import TripModal from "./TripModal";
import TripInfoModal from './TripInfoModal'
import { useAuth } from "../util/auth";
import { updateTrip, deleteTrip, useTripsByOwner, useCities } from "../util/db";
import "./DashboardItems.scss";
import moment from 'moment'
import { orderBy } from "firebase/firestore";
import TripView from "./TripView";
const tripTableDisplayLabels = {
  "dateA": "Date of arrival",
  "nights": "Nights",
  "pointA": "Point of arrival",
  "numpax": "Number of participants",
  "transport": "Transport",
  "pricePerPax": 'Total Trip Per Person',
  
}

const tripTableDisplayFilter = (item, itemKey)=>{
  const isntObject = typeof item[itemKey] != "object"
  const visibleKeys = Object.keys(tripTableDisplayLabels);
  return isntObject && visibleKeys.includes(itemKey);
}


function DashboardItems(props) {
  const auth = useAuth();
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries());

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useTripsByOwner(auth.user.uid);

  const {
    data: cities,
    status: citiesStatus,
    error: citiesError,
  } = useCities();

  const [creatingItem, setCreatingItem] = useState(false);
  const [creatingTrip, setCreatingTrip] = useState(false);

  const [updatingTripId, setUpdatingTripId] = useState(null);

  const [tripDetailsModalIndex, setTripDetailsModalIndex] = useState(-1);

  const itemsAreEmpty = !items || items.length === 0;

  const canUseStar =
    auth.user.planIsActive &&
    (auth.user.planId === "pro" || auth.user.planId === "business");
  let tripsWithMetadata = [];
 
 //transport = =ARRAYFORMULA((IFS(numPax<2,120,
 //numPax<4,(((3/(numPax))*30))*(numPax),
 //numPax<8,150,
 //numPax<15,ROUNDUP(numPax/4)*150,
 //numPax<26,ROUNDUP(numPax/45)*600,
 //numPax<32,ROUNDUP(numPax/50)*650,
 //numPax<45,ROUNDUP(numPax/50)*650,
 //numPax>44,ROUNDUP(numPax/50)*600) /numPax)*(numPax))
 //guide = =arrayformula(IFS(numPax<8,(((3/(numPax+1))*60)+5)*(numPax+1),
 //numPax<15,(130*1.21)+(5*numPax*1.21)*(ROUNDUP(numPax/15)),
 //numPax<26,(160*1.21)+(2*numPax*1.21)*(ROUNDUP(numPax/25)),
 //numPax>25,(150*1.21)+(2*numPax*1.21)*(ROUNDUP(numPax/25))))
  const getTransportPrice = (numPax)=>{
    var price = 0;
    if(numPax < 2){
      price = 120
    } else if(numPax < 4){
      price = (((3/(numPax))*30))*(numPax)
    } else if(numPax < 8){
      price = 150
    } else if(numPax < 15){
      price = Math.ceil(numPax/4)*150
    } else if(numPax < 26){
      price = Math.ceil(numPax/45)*600
    } else if(numPax < 32){
      price = Math.ceil(numPax/50)*650
    } else if(numPax < 45){
      price = Math.ceil(numPax/50)*650
    } else if(numPax > 44){
      price = Math.ceil(numPax/50)*600
    }
    return price;
  }
  if(itemsStatus !== "loading"){
    items.map((trip)=>{
      const nightsBooked = moment(trip.dateD).diff(moment(trip.dateA), "days");
      const numPax = (trip.numpax);
      const guide = Math.ceil(200*(nightsBooked+1)) //180*(nightsBooked+1);
      const transport = getTransportPrice(numPax)*(nightsBooked+1)
      //((numPax*30*(3/numPax))*numPax*nightsBooked))//numPax*35*(nightsBooked+1);
      
      
      
      const accommodation = Math.ceil(45*nightsBooked) 
      /*const guidePriceBasedOnNumPax = ({numPax }) => {
        if (trip.numpax > 4 ) {
         return ( Math.ceil(180*(nightsBooked+1))

         )
  } else { 
    return ( Math.ceil(200*(nightsBooked+1))
    )
  }
}*/

      tripsWithMetadata.push({
        ...trip,
        ...{
          nights: nightsBooked,
          guide: `EUR ${guide}`,
          transport: `EUR ${(transport)}`,        
          accommodation: `EUR ${Math.ceil((accommodation))}`,

          pricePerPax: `EUR ${Math.ceil(((guide+transport)/numPax)+accommodation)}`,

        }
      })
    })
  }
  const handleStarItem = (item) => {
    if (canUseStar) {
      updateTrip(item.id, { featured: !item.featured });
    } else {
      alert("You must upgrade to the pro or business plan to use this feature");
    }
  };

  const findCityByName = (cityName)=>{
    return cities.filter((city, index)=> city.name == cityName)[0];
  }
  /*const orderCitiesByRelatedCity = (arrivalCityName, additionalCities)=>{
    console.log(arrivalCityName, additionalCities);
    let newOrderedCities = [];
    const lastCityName = additionalCities[additionalCities.length-1]
    console.log(lastCityName);
    const orderByCity = (nextCityName)=>{
      const arrivalCity = findCityByName (nextCityName);
      let matchCity = null
      arrivalCity.relatedArrivals.some((cityName)=>{
        const isCityMatch = additionalCities.includes(cityName)
        if(isCityMatch)
          matchCity = cityName
        return isCityMatch
      });
      newOrderedCities.push(matchCity);
      if(matchCity != lastCityName){
        console.log(matchCity, lastCityName)
        orderByCity(matchCity);
        
      } else{
        alert();
      }
      //
    }
    orderByCity(arrivalCityName);
    return newOrderedCities;
    }*/
  const getCityByName = (cityName)=>{ // Make a function that makes an object with the kyes of the city nmames o they can be directly referecned without nested loops to improve performance.
    let cityObject = null
    console.log(cities, citiesStatus, citiesError, "cities11")
    for (const city of cities){
      if(cityName == city.name){
        cityObject = city;
        break;
      }
    }
    return cityObject
  }
  const orderCitiesByRelatedCity = (additionalCities)=>{ // Add index orderBy number for each related city for order fix.
    console.log(additionalCities);

    let newOrderedCities = [...additionalCities];
    newOrderedCities = newOrderedCities.map((tripCity)=>{
      return {...tripCity, ...getCityByName(tripCity.name)}
    })
    newOrderedCities.sort((previousCity, nextCity)=>previousCity.priority-nextCity.priority)    
    return newOrderedCities;
  }

  useEffect(()=>{
    if(params.action == "add_trip")
      setCreatingTrip(true)
  }, [])

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {tripDetailsModalIndex != -1 ? <TripInfoModal setUpdatingTripId={()=>{
        setUpdatingTripId(tripsWithMetadata[tripDetailsModalIndex].id)
        setTripDetailsModalIndex(-1);
      }} trip={tripsWithMetadata[tripDetailsModalIndex]} cities={cities} citiesStatus={citiesStatus} onDone={()=>setTripDetailsModalIndex(-1)}/> : ''}


      <div className="DashboardItems__card-items-wrapper">
        <Card className="card-items">
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
          >
            Trips
            <Button
              variant="primary"
              size="md"
              onClick={() => setCreatingTrip(true)}
            >
              Add Trip
            </Button>
          </Card.Header>

          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>Nothing yet. Click the button to add your first item.</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <ListGroup variant="flush">
              {tripsWithMetadata.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className={
                    `d-flex justify-content-between align-items-center` +
                    (item.featured ? " featured" : "")
                  }
                >
                  
                  <div style={{flex:1, flexDirection:'column'}}>
                    <label onClick={()=>{
                        setTripDetailsModalIndex(index);
                        
                      }}>{item.name} {index}</label>
                    <div style={{flex:1, flexDirection:'column'}}>
                      {
                        Object.keys(item)
                          .filter((itemKey)=>tripTableDisplayFilter(item, itemKey))
                          .map((itemKey, index)=>
                            <div>
                              <b>{tripTableDisplayLabels[itemKey]}: </b><label> {item[itemKey]}</label>
                            </div>)
                      }
                    </div>
                  </div>
                  
                  
                  <div>
                    
                    <Button 
                    variant="link"
                    aria-label="view trip"
                    onClick={() => TripView(item.id)}
                    className="action"
                    >
                    <i className="fas fa-eye" />
                    </Button>
                    <Button
                      variant="link"
                      aria-label="update"
                      onClick={() => setUpdatingTripId(item.id)}
                      className="action"
                    >
                      <i className="fas fa-edit" />
                    </Button>
                    <Button
                      variant="link"
                      aria-label="delete"
                      onClick={() => deleteTrip(item.id)}
                      className="action"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      </div>

      {creatingTrip && <TripModal onDone={() => setCreatingTrip(false)} />}

      {updatingTripId && (
        <TripModal
          id={updatingTripId}
          onDone={() => setUpdatingTripId(null)}
        />
      )}
    </>
  );
}

export default DashboardItems;
