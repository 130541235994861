import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import PricingSection from "../components/PricingSection";
import FeaturesSection from "./../components/FeaturesSection";
import CarouselSection from "./../components/CarouselSection";

import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Discover and Experience Al-Andalus"
        subtitle="We invite you to experience history through tradition and synergies, 
        rather than a dry political and warfare account. 
        Learn arts and skills while realising the spiritual value in the transmission 
        of knowledge and traditional apprenticeships. At the heart of traditional 
        knowledge is a holistic approach, striving to perfect ourselves towards a better 
        perception and understanding of our existence. Al-Andalus Experience is about looking into our 
        past, present and future, conting on technology and communication to learn where we each stand 
        today in a global civilisation, what we want to highlight, learn about, the experiences and 
        knowledge we want to fill our lifes with, and the company we choose to share with."
      />
       <Meta title="Specialities" />
      <PricingSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Your best choice is here."
        subtitle="Choose your style, plan and invite others, host, sponsor trips or share group costs with other participants."
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="What You Get"
        subtitle="A choice for all group sizes and styles, when planning trips and events in Andalusia, Spain." //and beyond into Portugal or Morocco."
      />
      <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company"
        subtitle=""
      />
      <TestimonialsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <StatsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/auth/:type"
      />
    </>
  );
}

export default AboutPage;
