import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import FormField from "./FormField";
import { useAuth } from "../util/auth";
import { useTrip, updateTrip, createTrip, useTrips, useCities, updateTripPlans } from "../util/db";
import moment from 'moment';
import Dayplanner from "./TripModal/DayPlanner";
import AdditionalCities from "./TripModal/AdditionalCities";
function EditItemModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [currentlySelectedCity, setCurrentlySelectedCity] = useState(0);
  const [selectedAdditionalCities, setSelectedAdditionalCities] = useState([]);
  const selectedCitiesNames = selectedAdditionalCities.map(city=>city.name);
  const [selectedCityIndexForPlanner, setSelectedCityIndexForPlanner] = useState(-1);
  const addAdditionalCity = (city)=>{
    let updatedAdditionalCities = [...selectedAdditionalCities];
    updatedAdditionalCities.splice(updatedAdditionalCities.length-1, 0, {name: city, nights:1, type:"interim"})
    setSelectedAdditionalCities(updatedAdditionalCities);
    setCurrentlySelectedCity(0)
  };
  const setArrivalCity = (city, nights=0)=>{
    let updatedAdditionalCities = [...selectedAdditionalCities]
    updatedAdditionalCities[0] = {name:city, nights, type: "arrival"}
    setSelectedAdditionalCities(updatedAdditionalCities);
  }
  const setDepartureCity = (city, nights=0)=>{
    let updatedAdditionalCities = [...selectedAdditionalCities]
    const hasDepartureCity = ()=> (updatedAdditionalCities.filter(city=>city.type == "departure").length > 0);
    console.log(hasDepartureCity());
    if((updatedAdditionalCities.length == 1) && !hasDepartureCity()){
      console.log(updatedAdditionalCities.length, hasDepartureCity());
      updatedAdditionalCities.push({name:city, nights, type: "departure"})
    }
    else
      updatedAdditionalCities[updatedAdditionalCities.length-1] = {name:city, nights, type: "departure"}

    setSelectedAdditionalCities(updatedAdditionalCities);
  }

  const updateAdditionalCityNightsAtIndex = (cityIndex, nightsNumber)=>{
    let updatedSelectedAdditionalCities = [...selectedAdditionalCities]
    let cityAtIndex = updatedSelectedAdditionalCities[cityIndex];
    cityAtIndex['nights'] = nightsNumber
    setSelectedAdditionalCities(updatedSelectedAdditionalCities);
  }
  const removeAdditionalCityAtIndex = (cityIndex)=>{
    let updatedAdditionalCities = [...selectedAdditionalCities];
    updatedAdditionalCities.splice(cityIndex, 1);
    setSelectedAdditionalCities(updatedAdditionalCities);
  }

  const { register, handleSubmit, errors } = useForm();

  // This will fetch item if props.id is defined
  // Otherwise query does nothing and we assume
  // we are creating a new item.
  const { data: itemData, status: itemStatus } = useTrips(props.id);
  const { data: cityData, status: cityStatus } = useCities();
  useEffect(()=>{

    if(props.id && itemStatus != "loading")
      setSelectedAdditionalCities(itemData.additionalCities);
  }, [itemData]);

  // If we are updating an existing item
  // don't show modal until item data is fetched.
  if (props.id && itemStatus !== "success") {
    return null;
  }
  

  

  const onSubmit = (data) => {
    setPending(true);
    const getDepartureDateFromArrivalDateByOffset = (arrivalDate, nights)=> moment(arrivalDate).add(nights, 'days').format('YYYY-MM-DD')
    const getTotalCityNights = (cities)=>{
      var totalNights = 0;
      cities.forEach((city)=>{
        totalNights = totalNights+parseInt(city.nights)
      })
      return totalNights;
    }
    const totalNights = getTotalCityNights(selectedAdditionalCities);
    console.log(data, 'data debug');
    if(props.id)
      data['additionalCities'] = selectedAdditionalCities;
    const query = props.id
      ? updateTrip(props.id, {...data, ...{dateD:getDepartureDateFromArrivalDateByOffset(data.dateA, totalNights), nights:totalNights}})
      : createTrip({ 
        owner: auth.user.uid, 
        ...data, 
        additionalCities:selectedAdditionalCities, 
        dateD:getDepartureDateFromArrivalDateByOffset(data.dateA, totalNights), 
        nights:totalNights });

    query
      .then((response) => {
        // Let parent know we're done so they can hide modal
        console.log(response);
        props.onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };
  const updateAdditionalCityAtIndex = (updateIndex, updatedCity)=>{
    const updatedAdditionalCities = selectedAdditionalCities.map((oldCity, cityIndex)=>{
      if(cityIndex == updateIndex){
        return updatedCity
      }
      return oldCity
    })
    // save to firebase here.
    updateTrip(props.id, {additionalCities:updatedAdditionalCities})
    //setSelectedAdditionalCities(updatedAdditionalCities);
  }
  console.log(selectedAdditionalCities);
  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        {props.id && <>Update</>}
        {!props.id && <>Create</>}
        {` `}Item
      </Modal.Header>
      <Modal.Body>
        {formAlert && (
          <FormAlert type={formAlert.type} message={formAlert.message} />
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="formName">
            <FormField
              size="lg"
              name="name"
              type="text"
              placeholder="Trip Name"
              defaultValue={itemData && itemData.name}
              error={errors.name}
              autoFocus={true}
              inputRef={register({
                required: "Please enter a name",
              })}
            />
            <FormField name="pointA" as="select" onChange={(event)=>setArrivalCity(event.target.value, 0)} placeholder="Arrival city/location" type="select" defaultValue={itemData && itemData['pointA'] ? itemData['pointA'] : 0}>
              <option disabled value={0}>Arrival location/city</option>
              {cityStatus != "loading" ? cityData.map((city, index)=><option value={city.name}>{city.name}</option>) : ''}
            </FormField>
            <FormField name="pointD" as="select" onChange={(event)=>setDepartureCity(event.target.value, 0)} placeholder="Departure city/location" type="select" defaultValue={itemData && itemData['pointD'] ? itemData['pointD'] : 0}>
              <option disabled value={0}>Departure location/city</option>
              {cityStatus != "loading" ? cityData.map((city, index)=><option>{city.name}</option>) : ''}
            </FormField>
             <FormField
              size="lg"
              name="dateA"
              type="date"
              placeholder="Date of arrival"
              defaultValue={itemData && itemData.dateA}
              error={errors.name}
              autoFocus={true}
              inputRef={register({
                required: "Please enter an arrival date",
              })}
            />
            <FormField
              size="lg"
              name="numpax"
              type="number"
              placeholder="Number of participants"
              defaultValue={itemData && itemData.numpax}
              error={errors.name}
              autoFocus={true}
              inputRef={register({
                required: "Please enter a name",
              })}
            />
          </Form.Group>
          <h4 style={{marginTop:10}}>Add cities/locations</h4>
          {
            selectedCityIndexForPlanner == -1 ? 
            <AdditionalCities {...{cityStatus, selectedAdditionalCities, removeAdditionalCityAtIndex, updateAdditionalCityAtIndex, cityData, selectedCitiesNames, currentlySelectedCity, setCurrentlySelectedCity, updateAdditionalCityNightsAtIndex, addAdditionalCity, pending, setCurrentlySelectedCity, setSelectedCityIndexForPlanner}} /> 
            :
            <Dayplanner cityId={props.id} selectedCityIndexForPlanner={selectedCityIndexForPlanner} setSelectedCityIndexForPlanner={setSelectedCityIndexForPlanner} selectedAdditionalCities={selectedAdditionalCities} setSelectedCityIndexForPlanner={setSelectedCityIndexForPlanner} updateAdditionalCityAtIndex={updateAdditionalCityAtIndex}/>
          }
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditItemModal;
