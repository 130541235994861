import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import FormField from "./FormField";
import TripView from "../components/TripView"
import { useAuth } from "../util/auth";
import { useTrip, updateTrip, createTrip, useTrips, useCities } from "../util/db";
const tripTableDisplayLabels = {
  "dateA": "Date of arrival",
  "dateD": "Date of departure",
  "pointA": "Point of arrival",
  "pointD": 'Point of departure',
  "nights": "Nights",
  "numpax": "Number of participants",
  "accommodation": 'Accommodation',
  "guide": "Guide",
  "transport": 'Transport',
  "pricePerPax": 'Total Trip Per Person',
  
}
const tripTableDisplayFilter = (item, itemKey)=>{
  const isntObject = typeof item[itemKey] != "object"
  const visibleKeys = Object.keys(tripTableDisplayLabels);
  return isntObject && visibleKeys.includes(itemKey);
}





function EditItemModal(props) {
  const auth = useAuth();
  const {cities, citiesStatus} = props;
  const orderCitiesByRelatedCity = (additionalCities)=>{ // Add index orderBy number for each related city for order fix.
    console.log(additionalCities);
  
    let newOrderedCities = [...additionalCities];
    newOrderedCities = newOrderedCities.map((tripCity)=>{
      return {...tripCity, ...getCityByName(tripCity.name)}
    })
    newOrderedCities.sort((previousCity, nextCity)=>previousCity.priority-nextCity.priority)    
    return newOrderedCities;
  }
  const getCityByName = (cityName)=>{ // Make a function that makes an object with the kyes of the city nmames o they can be directly referecned without nested loops to improve performance.
    let cityObject = null
    for (const city of cities){
      if(cityName == city.name){
        cityObject = city;
        break;
      }
    }
    return cityObject
  }
  console.log(props,"debug123")
  if(props.trip == undefined){
    return <></>
  }
  return (
    <Modal show={true} centered={true} animation={false} onHide={props.onDone}>
      <Modal.Header closeButton={true}>
        {props.trip.name}
      </Modal.Header>

      <Modal.Body>
        <ListGroup variant="flush">

          <ListGroup.Item
            
            className={
              `d-flex justify-content-between align-items-center` +
              (props.trip.featured ? " featured" : "")
            }
          >

            <div style={{ flex: 1, flexDirection: 'column' }}>
              <label>{props.trip.name}</label>
              <div style={{ flex: 1, flexDirection: 'column' }}>
                {
                  Object.keys(props.trip)
                    .filter((itemKey) => tripTableDisplayFilter(props.trip, itemKey))
                    .map((itemKey, index) =>
                      <div>
                        <b>{tripTableDisplayLabels[itemKey]}: </b><label> {props.trip[itemKey]}</label>
                      </div>)
                }
                <h4>City nights planned</h4>
                <div style={{ flex: 1, flexDirection: 'column' }}>
                  {props.trip['additionalCities'] && citiesStatus != "loading" ? orderCitiesByRelatedCity(
                    props.trip['additionalCities']).map((city, index) => <b>{city.name} for {city.nights} nights <br /></b>
                    ) : 'No additioanl cities selected'}
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="link"
                aria-label="star"
                className={
                  `action` + (props.trip.featured ? ` star-featured` : "")
                }
              >
                <i className="fas fa-star" />
              </Button>
              <Button
                variant="link"
                aria-label="update"
                className="action"
                onClick={props.setUpdatingTripId}
              >
                <i className="fas fa-edit" />
              </Button>
              <Button
                variant="link"
                aria-label="delete"
                className="action"
              >
                <i className="fas fa-trash" />
              </Button>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default EditItemModal;
