import React, {useEffect} from "react";
import Card from "react-bootstrap/Card";
import { useAuth } from "../util/auth";
import { useTripsByOwner } from "../util/db";
const TripView = () =>{
    const auth = useAuth();
    const {
        data: trips,
        status: itemsStatus,
        error: itemsError,
    } = useTripsByOwner(auth.user.uid);
    const initCities = cities=>{
        let days = []
        cities.map((city)=>{
            city.plans.forEach((plan)=>{
                days.push({
                    city: city.name,
                    type:city.type,
                    plan
                })
            })
        })  
        return days;  
    }

    const getDayLabel = (index, type)=>{
        switch (type) {
            case 'arrival':
                return `Arrival Day`
                break;
            case 'departure':
                return `Departure Day`
                break;
            default:
                return `Day ${index+1}`
                break;
        }
    }

    return trips ? trips.map((trip)=><Card className="card-items">
    <Card.Header
      as="h5"
      className="d-flex justify-content-between align-items-center"
        >{trip.name}</Card.Header>
        
        {initCities(trip.additionalCities).map((city, index)=><div className={"cityDayNight"}>
            <b>{getDayLabel(index, city.type)} in {city.city    } doing</b>  {city.plan.name}
        </div>)}
        
    </Card>) : <>None</>
}

export default TripView;