import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import CarouselSection from "./../components/CarouselSection";
import Section from "../components/Section";
import { Container } from "react-bootstrap";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import {useCities, usePlans} from '../util/db.js';
import { map } from "jquery";
import "../components/PlansFront.scss";

import { Button } from "react-bootstrap";

// TODO LIST TRIP array of nights.. ie: night 1 -> city night 1 ->  night 2 -> city night 2 ->

// wedge in -> one "day " ahead of every night, ie: day1, night1, day2, night2

//list information from these days or nights

// cities should have a standard plan for day 1 , and 2 sometimes... 
// we need to list a city (day title, day number) description for day 1+(night1), day 2+(night2) , day 3+(night3)... etc...
//this would be our view..



function IndexPage(props) {
  const {data:allCities} = useCities();
  const {data:allPlans} = usePlans();
  const getPlansWithCities = (plans, cities)=>{
    const findRelatedCitiesForPlan = (relatedCities)=> cities.filter((city)=>{
      return relatedCities.includes(city.name)
    })
    return plans.map((plan)=>{
      plan.cities = findRelatedCitiesForPlan(plan.relatedCities);
      return plan;
    })
  }
  const arePlansAndCitiesLoaded = allCities && allPlans
  return (
    <>
      <Meta />
      
      <CarouselSection

        items={[
          {
            title: "Madrid to Al-Andalus",
            heading: "6 nights",
            image: "https://alandalus-experience.com/backup/wp-content/uploads/2021/07/Abdullah-Almekhyal-image-5-scaled-1.jpeg",
            caption: "Plan privately, or plan and share for others to join this trip, with all of our available plans and choices, guidance and support from now until your trip is carried out and concluded start to end.",
          },
          {
            title: "Discover Al-Andalus",
            heading: "4 nights ",
            image: "https://alandalus-experience.com/backup/wp-content/uploads/2021/07/3.jpg",
            caption: "Discovering the Islamic Heritage of Spain.",
          },
          {
            title: "The Heart of Al-Andalus",
            heading: "5 nights.",
            image: "https://alandalus-experience.com/backup/wp-content/uploads/2021/07/Abdullah-Almekhyal-image-5-scaled-1.jpeg",
            caption: "Discovering the Islamic Heritage of Spain.",
          },
        ]}
      />

      <Section>
<Container className="text-center">

      <section class="frontPlans">

      <h1>Featured Daytime Plans</h1>

      <div class="PlansFront">
        {
          arePlansAndCitiesLoaded ? getPlansWithCities(allPlans, allCities).map(plan=><div>
            <div class="planListItem"><h3>{plan.name}</h3>
            <p>{plan.description}</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Plan</a></Button>
            <br />
            <div class="cities">
            <b>Available in:</b>
            <ul>
              {plan.cities.map((city)=><li>{city.name}</li>)}
             
            </ul></div>
            </div>
          </div>) : ''
        }
      </div>
      </section>


        </Container></Section>
       
        <HeroSection
        bg="secondary"
        textColor="light"
        size="lg"
        bgImage="https://alandalus-experience.com/backup/wp-content/uploads/2021/07/Abdullah-Almekhyal-image-5-scaled-1.jpeg"
        bgImageOpacity={0.3}
        title="Al-Andalus Experience's Online Trip Planner"
        subtitle="Get a start on any plan, create your own journey, for you and your guest list."
        buttonText="Start Planning"
        buttonColor="light"
        buttonPath="/auth/:type"

      />
       <Section>
         <Container className="text-center">
        <section class="frontPlans">
  <h1>Shared Group Trips Available</h1>
      <div class="PlansFront">
        {
          <div>
            
            <div class="planListItem"><h3>Sample Trip</h3>
            <p>Sample trip description.</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Trip</a></Button>
            <br />
            <div class="cities">
             
            </div>
            </div>
          </div> 
        }


{
          <div>
            <div class="planListItem"><h3>Sample Trip</h3>
            <p>Sample trip description.</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Trip</a></Button>
            <br />
            <div class="cities">
             
            </div>
            </div>
          </div> 
        }

{
          <div>
            <div class="planListItem"><h3>Sample Trip</h3>
            <p>Sample trip description.</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Trip</a></Button>
            <br />
            <div class="cities">
             
            </div>
            </div>
          </div> 
        }
        
{
          <div>
            <div class="planListItem"><h3>Sample Trip</h3>
            <p>Sample trip description.</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Trip</a></Button>
            <br />
            <div class="cities">
             
            </div>
            </div>
          </div> 
        }

{
          <div>
            <div class="planListItem"><h3>Sample Trip</h3>
            <p>Sample trip description.</p>
            <Button class="button"><a href="http://localhost:3000/auth/:type" >View This Trip</a></Button>
            <br />
            <div class="cities">
             
            </div>
            </div>
          </div> 
        }
      </div>
      </section>
      <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Join our trip updates"
        subtitle="Be the first to know when new trips are available"
        buttonText="Sign Up"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="Login to access all public trips!"
      />
      </Container></Section>


      
    


       
      
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Are you a local expert? Do you have an experience or plan to share?"
        subtitle="*Eres experto en tu zona? Tienes algún plan o experiencia que compartir? "
        buttonText="Get Your Plans Listed"
        buttonColor="light"
        buttonPath="/auth/:type"
      />
      
    </>
    
  );
}

export default IndexPage;
