import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Our Specialities"
        subtitle="Choose your style, plan and invite others, host, sponsor trips or share group costs with other participants."
      />
    </>
  );
}

export default PricingPage;
