import { useState, useEffect } from "react"
import FormField from "../FormField";

import { usePlansForCity } from "../../util/db";
const DayPlanner = ({selectedCityIndexForPlanner, setSelectedCityIndexForPlanner, selectedAdditionalCities, updateAdditionalCityAtIndex})=>{
    const {status:plansStatus, data: availablePlans} = usePlansForCity(selectedAdditionalCities[selectedCityIndexForPlanner].name);

    
    //ToDo Zaruq_ Populate firestore cities with related plan id Arrays.
    
    //TODO: make a Plans viewing window to see plans in further detail, options to add or delete plan from any city/day.
    //Make a generic component to view Plans or cities, or recicle the reviews component listing features, to be used with cities and plans.
    //bring in a way to make plans from front side and they be saved as objects, 
    //make the super admin able to approve new form plans.
    const [cities, setCities] = useState([
    ])
    const initCities = cities=>{
        console.log(cities)
        if(cities[0].plans){
            setCities(cities);
            
            return;
        }
            
            console.log(cities)
        const updatedCities = cities.map((city)=>{
            city.plans = [];
            for (let index = 0; index < parseInt(city.nights); index++) {
                city.plans.push({});
            }
            return city;
        })
        setCities(updatedCities);
    }
    const saveAndClose = (index, city)=>{
        console.log(index, city);
    
        updateAdditionalCityAtIndex(index, city);
        setSelectedCityIndexForPlanner(-1)
    }

    //TODO add form to create plans (for special users only, eventually
    
    useEffect(()=>{
        initCities([selectedAdditionalCities[selectedCityIndexForPlanner]]);
    }, [])
    const setPlansForCityNight = (event)=>{
        const [cityName, cityNightIndex] = event.target.name.split(",")
        const planName = event.target.value;
        const updatedCities = cities.map((oldCity)=>{
            if(oldCity.name == cityName){
                oldCity.plans[cityNightIndex] = {name:planName}
            }
                return oldCity;
        })
        console.log(updatedCities);
        setCities(updatedCities);
    }
    console.log(cities);
 return (<>
    <div style={{width:'100%', height:100}}>
        <h3>Day Planner</h3>
    </div>
    <div>
        <div>
            <ul>
                {cities.map((city, index)=>(<>{
                 
                    city.plans.map((nightPlan, index)=><li>
                        {console.log(city, nightPlan, availablePlans)}
                    <label>{city.name} Night {index+1}</label>
                    <FormField name={`${city.name},${index}`} as="select" placeholder="Avialbale plans" type="select" onChange={setPlansForCityNight}>
                        <option disabled selected={!nightPlan.name} value={0}>Available Plans</option>
                        {availablePlans ? availablePlans.map((plan, index)=><option value={plan.name} 
                        selected={plan.name == nightPlan.name}>{plan.name}</option>) : ""}
                    </FormField>
                </li>)
                }</>))}
                <button onClick={()=>saveAndClose(selectedCityIndexForPlanner, cities[0])}>Done</button>
            </ul>
        </div>
    </div>
 </>)
}

export default DayPlanner;
