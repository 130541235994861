import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormField from "../FormField";
const AdditionalCities = ({cityStatus, selectedAdditionalCities, removeAdditionalCityAtIndex, cityData, selectedCitiesNames, currentlySelectedCity, setCurrentlySelectedCity, updateAdditionalCityNightsAtIndex, updateAdditionalCityAtIndex, addAdditionalCity, pending, setSelectedCityIndexForPlanner})=>{
    return <>
        {selectedAdditionalCities.map((city, index)=><>
            <b style={{display:'block', width:'100%'}}>{city.name} {/*{city.type}*/} <input type="number" min={1} max={30} value={city.nights} onChange={(event)=>updateAdditionalCityNightsAtIndex(index, event.target.value)}/> <button onClick={(event)=>{
                event.preventDefault();
                removeAdditionalCityAtIndex(index);
                
                }}>delete</button> <button onClick={(event)=>{event.preventDefault(); setSelectedCityIndexForPlanner(index); console.log(city);}}>Plans</button></b>
            </>)}
            <FormField name="pointA" as="select" placeholder="Additional city/location" type="select" onChange={(event)=>{setCurrentlySelectedCity(event.target.value);}} value={currentlySelectedCity}>
              <option disabled selected={true} value={0}>Arrival location/city</option>
              {cityStatus != "loading" ? cityData.filter((city)=> !selectedCitiesNames.includes(city.name)).map((city, index)=><option value={city.name}>{city.name}</option>) : ''}
            </FormField>
            <Button size="md" varient="secondary" onClick={()=>addAdditionalCity(currentlySelectedCity)} disabled={currentlySelectedCity == 0}>Add city/location</Button>
          <Button size="lg" variant="primary" type="submit" disabled={pending}>
            <span>Save</span>

            {pending && (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden={true}
                className="ml-2"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Button>
    </>
}

export default AdditionalCities;